<template>
  <div class="card card-custom gutter-b">
    <div class="card-header pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"> Nutzer Einstellungen </span>
      </h3>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-body">
      <b-form-checkbox v-model="hideRoleIndicators">
        Rollen Indikatoren verstecken (Seite neu laden)
      </b-form-checkbox>
      <b-form-checkbox v-model="alwaysHideReiseterminCompactHeader">
        Reisetermin Scroll-Header verstecken
      </b-form-checkbox>
      <b-form-checkbox v-model="isAnimationEnabled"> Seiten Animationen </b-form-checkbox>
      <b-row class="mt-2">
        <b-col cols="6">
          <Multiselect
            v-model="customRoles"
            tag-placeholder="Add this as new tag"
            placeholder="Rollen im Manager simulieren"
            label="name"
            track-by="key"
            :options="roleOptions"
            :multiple="true"
            :taggable="true"
          />
        </b-col>
        <b-col cols="1">
          <b-button
            v-b-tooltip.hover
            size="sm"
            class="mx-1"
            pill
            variant="primary"
            title=" Die Rollen sind nur für Rollen Indikatoren relevant und haben nur
            Auswirkungen im Manager. Nicht auf die Kommunikation mit dem Core."
          >
            <b-icon-info-circle />
          </b-button>
          <b-button size="sm" @click="onClickResetCustomRoles">Reset</b-button>
        </b-col>
        <b-col cols="2" style="font-size: 0.8rem">
          <b>Server Rollen: </b>{{ $store.getters.parsedRoles.join(', ') || 'Keine' }}<br /><b
            >Aktuelle Rollen: </b
          >{{ customRoles.map(r => r.key).join(', ') || 'Keine' }}
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import {
  SET_HIDE_ROLE_INDICATORS,
  SET_ALWAYS_HIDE_REISETERMIN_COMPACT_HEADER,
  SET_IS_ANIMATION_ENABLED,
  SET_CUSTOM_ROLES,
} from '@/core/user/stores/user-settings-local.module.js';
import { Role } from '@/core/user/roles.js';
import { mapState } from 'vuex';
import Multiselect from '@/components/common/custom-multiselect.vue';

export default {
  name: 'einstellungen-user',
  components: {
    Multiselect,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      lastVisitedReiseterminId: state => state.userSettingsLocal.lastVisitedReiseterminId,
      lastVisitedReiseterminKuerzel: state => state.userSettingsLocal.lastVisitedReiseterminKuerzel,
    }),
    roleOptions() {
      return Object.entries(Role).map(([key, value]) => ({ name: value, key: key }));
    },
    customRoles: {
      get() {
        return this.$store.state.userSettingsLocal.customRoles;
      },
      set(value) {
        this.$store.commit(SET_CUSTOM_ROLES, value);
      },
    },
    isAnimationEnabled: {
      get() {
        return this.$store.state.userSettingsLocal.isAnimationEnabled;
      },
      set(value) {
        this.$store.commit(SET_IS_ANIMATION_ENABLED, value);
      },
    },
    hideRoleIndicators: {
      get() {
        return this.$store.state.userSettingsLocal.hideRoleIndicators;
      },
      set(value) {
        this.$store.commit(SET_HIDE_ROLE_INDICATORS, value);
      },
    },
    alwaysHideReiseterminCompactHeader: {
      get() {
        return this.$store.state.userSettingsLocal.alwaysHideReiseterminCompactHeader;
      },
      set(value) {
        this.$store.commit(SET_ALWAYS_HIDE_REISETERMIN_COMPACT_HEADER, value);
      },
    },
  },
  methods: {
    onClickResetCustomRoles() {
      this.$store.commit(SET_CUSTOM_ROLES, []);
    },
  },
};
</script>
